import React from 'react'
import { graphql } from 'gatsby'

import { Layout, PostCardCategory } from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/BlockSerializer"

/**
* Tag page (/tag/)
*
* Loads all tags or categories
*
*/
const Tags = ({ data, location, pageContext }) => {
    const sanityCategories = data.allSanityCategory.edges
    const siteSettings = data.allSanitySiteSettings.edges[0].node

    return (
        <>
            <MetaData
                data={data}
                location={location}
                title={`${siteSettings.tagsPage.seo.metaTitle}`}
                description={siteSettings.tagsPage.seo.metaDescription}
                type="series"
            />
            <Layout>
                <div className="container">
                    <h1>{siteSettings.tagsPage.title}</h1>
                    <section className="post-feed">
                        {sanityCategories != null && sanityCategories.map(({ node }, id) => (
                            <React.Fragment key={id}>
                                <PostCardCategory category={node} sanitySiteSettings={siteSettings} />
                            </React.Fragment>
                        ))}
                    </section>
                    <p></p>
                    <BlockSerializer>{siteSettings.tagsPage._rawBody}</BlockSerializer>
                </div>
            </Layout>
        </>
    )
}

export default Tags

export const pageQuery = graphql`
    query {
        allSanityCategory {
            edges {
                node {
                    name
                    _rawDescription
                    slugWithRedirectValidation {
                        ...SlugWithRedirectValidationFields
                    }
                    image {
                        alt
                        hotspot {
                            height
                            width
                            x
                            y
                        }
                        crop {
                            bottom
                            left
                            right
                            top
                        }
                        asset {
                            _id
                            metadata {
                            preview: lqip
                            dimensions {
                                height
                                width
                            }
                            }
                        }
                    }
                }
            }
        }
        allSanitySiteSettings {
            edges {
                node {
                    useCategoriesRoot
                    tagsPage {
                        title
                        slugWithRedirectValidation {
                            ...SlugWithRedirectValidationFields
                        }
                        _rawBody(resolveReferences: {maxDepth: 5})
                        seo {
                            ...SeoFields
                        }
                    }
                }
            }
        }
    }
`
